import { Component } from "react";
import { Link } from "react-router-dom";
import { getSpareParts } from "./api";
import { Info, Loading, SparePart } from "./other";

import "./styles/spareParts.scss";

export default class SpareParts extends Component {

    constructor(props) {

        super(props);

        this.state = { requesting: false, info: null, spareParts: null };
    }

    componentDidMount() {

        this.setState({ requesting: true });
        getSpareParts().then((spareParts) => {
            this.setState({ requesting: false, spareParts });
        }).catch((message) => {
            if (message === "Invalid token") {
                localStorage.removeItem("token");
                window.location.reload();
            } else this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
        });
    }

    render() {

        document.title = "Pièces détachées - Servelec SAV";

        return <div className="spareParts">

            <div className="title">{document.title}</div>

            {this.state.info}
            {this.state.requesting ? <Loading /> : null}

            <div className="box"><Link to="/spareParts/new" className="link">Nouvelle pièce détachée</Link></div>

            {this.state.spareParts
                ? (this.state.spareParts.length > 0
                    ? <div className="files"><table>
                        <thead><tr>
                            <th>Nom</th>
                            <th>Référence</th>
                            <th>Référence fournisseur</th>
                            <th>Fabriquant</th>
                            <th>Prix d'achat HT</th>
                            <th>Stock réel</th>
                            <th>Position</th>
                            <th>Commentaires</th>
                        </tr></thead>
                        <tbody>{this.state.spareParts.map((sparePart) => <SparePart key={sparePart.id} sparePart={sparePart} />)}</tbody>
                    </table></div>
                    : <div className="noresults">Aucun résultat</div>)
                : null}
        </div>
    }
}
