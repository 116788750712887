import { Component, createRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Info, Loading } from "./other";
import { deleteSparePart, editSparePart, getSparePart } from "./api";

import "./styles/spareParts.scss";

class SparePart extends Component {

    constructor(props) {

        super(props);

        this.nameInput = createRef();
        this.supplierInput = createRef();
        this.referenceInput = createRef();
        this.supplierReferenceInput = createRef();
        this.makerInput = createRef();
        this.purchasePriceHtInput = createRef();
        this.sellingPriceTtcInput = createRef();
        this.realStockInput = createRef();
        this.locationInput = createRef();
        this.commentsInput = createRef();

        this.state = { requesting: false, info: null, sparePart: null, editing: false };
    }

    componentDidMount() {

        const id = parseInt(this.props.params.id);
        if (isNaN(id)) {
            this.setState({ info: <Info>Ce numéro de pièce détachée n'est pas valide !</Info> });
            return;
        }

        this.setState({ requesting: true, info: null });
        getSparePart(id).then((sparePart) => {
            this.setState({ sparePart, requesting: false });
        }).catch((error) => {
            if (error === "Invalid token") {
                localStorage.removeItem("token");
                window.location.reload();
            } else if (error === "This spare part does not exist")
                this.setState({ requesting: false, info: <Info>Cette pièce détachée n'existe pas !</Info> });
            else this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
        });
    }

    render() {

        document.title = `Pièce détachée ${this.state.sparePart ? this.state.sparePart.name : ""} - Servelec SAV`;

        const toggleEdit = () => {

            if (!this.state.editing) {
                this.setState({ editing: true });
                return;
            }

            const purchasePriceHt = parseFloat(this.purchasePriceHtInput.current.value);
            if (isNaN(purchasePriceHt)) {
                this.setState({ info: <Info>Le prix d'achat HT doit être un nombre valide !</Info> });
                return;
            }

            const sellingPriceTtc = parseFloat(this.sellingPriceTtcInput.current.value);
            if (isNaN(sellingPriceTtc)) {
                this.setState({ info: <Info>Le prix de vente TTC doit être un nombre valide !</Info> });
                return;
            }

            const realStock = parseInt(this.realStockInput.current.value);
            if (isNaN(realStock)) {
                this.setState({ info: <Info>Le stock réel doit être un nombre valide !</Info> });
                return;
            }

            const edits = {};
            if (this.nameInput.current.value !== this.state.sparePart.name) edits.name = this.nameInput.current.value;
            if (this.supplierInput.current.value !== this.state.sparePart.supplier) edits.supplier = this.supplierInput.current.value;
            if (this.referenceInput.current.value !== this.state.sparePart.reference) edits.reference = this.referenceInput.current.value;
            if (this.supplierReferenceInput.current.value !== this.state.sparePart.supplierReference) edits.supplierReference = this.supplierReferenceInput.current.value;
            if (this.makerInput.current.value !== this.state.sparePart.maker) edits.maker = this.makerInput.current.value;
            if (purchasePriceHt !== this.state.sparePart.purchasePriceHt) edits.purchasePriceHt = purchasePriceHt;
            if (sellingPriceTtc !== this.state.sparePart.sellingPriceTtc) edits.sellingPriceTtc = sellingPriceTtc;
            if (realStock !== this.state.sparePart.realStock) edits.realStock = realStock;
            if (this.locationInput.current.value !== this.state.sparePart.location) edits.location = this.locationInput.current.value;
            if (this.commentsInput.current.value !== this.state.sparePart.comments) edits.comments = this.commentsInput.current.value;

            if (Object.keys(edits).length === 0) {
                this.setState({ editing: false });
                return;
            }

            this.setState({ requesting: true, info: null });
            editSparePart(this.state.sparePart.id, edits).then((sparePart) => {
                this.setState({ sparePart, editing: false, requesting: false });
            }).catch((message) => {
                if (message === "Name cannot be empty")
                    this.setState({ requesting: false, info: <Info>Veuillez préciser un nom</Info> });
                else if (message === "Name too long")
                    this.setState({ requesting: false, info: <Info>Le nom ne doit pas faire plus de 50 caractères</Info> });
                else if (message === "Supplier too long")
                    this.setState({ requesting: false, info: <Info>Le fournisseur ne doit pas faire plus de 20 caractères</Info> });
                else if (message === "Reference too long")
                    this.setState({ requesting: false, info: <Info>La référence ne doit pas faire plus de 20 caractères</Info> });
                else if (message === "Supplier reference too long")
                    this.setState({ requesting: false, info: <Info>La référence fournisseur ne doit pas faire plus de 20 caractères</Info> });
                else if (message === "Maker too long")
                    this.setState({ requesting: false, info: <Info>Le fabricant ne doit pas faire plus de 20 caractères</Info> });
                else if (message === "Location cannot be empty")
                    this.setState({ requesting: false, info: <Info>Veuillez préciser une position</Info> });
                else if (message === "Location too long")
                    this.setState({ requesting: false, info: <Info>La position ne doit pas faire plus de 20 caractères</Info> });
                else if (message === "Comments too long")
                    this.setState({ requesting: false, info: <Info>Le commentaire ne doit pas faire plus de 500 caractères</Info> });
                else this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
            });
        }

        const processDelete = () => {

            const valid = window.confirm("Voulez vous vraiment supprimer la pièce détachée ?");
            if (!valid) return;

            this.setState({ requesting: true, info: null });
            deleteSparePart(this.state.sparePart.id)
                .then(() => this.props.navigate("/spareParts"))
                .catch(() => this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> }));
        }

        return <div className="sparePart">

            <div className="title">Pièce détachée{this.state.sparePart ? ` n°${this.state.sparePart.id}` : ""}</div>

            {this.state.info}
            {this.state.requesting ? <Loading /> : null}

            {!this.state.sparePart ? null : <div>

                <div className="field-group">
                    <div className="field">
                        <div className="subtitle">Nom :</div>
                        {this.state.editing
                            ? <input ref={this.nameInput} defaultValue={this.state.sparePart.name} disabled={this.state.requesting}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div>{this.state.sparePart.name}</div>}
                    </div>
                    <div className="field">
                        <div className="subtitle">Fournisseur :</div>
                        {this.state.editing
                            ? <input ref={this.supplierInput} defaultValue={this.state.sparePart.supplier} disabled={this.state.requesting}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div>{this.state.sparePart.supplier || <i>Non précisé</i>}</div>}
                    </div>
                    <div className="field">
                        <div className="subtitle">Fabricant :</div>
                        {this.state.editing
                            ? <input ref={this.makerInput} defaultValue={this.state.sparePart.maker} disabled={this.state.requesting}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div>{this.state.sparePart.maker || <i>Non précisé</i>}</div>}
                    </div>
                </div>

                <div className="field-group">
                    <div className="field">
                        <div className="subtitle">Référence :</div>
                        {this.state.editing
                            ? <input ref={this.referenceInput} defaultValue={this.state.sparePart.reference} disabled={this.state.requesting}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div>{this.state.sparePart.reference || <i>Non précisée</i>}</div>}
                    </div>
                    <div className="field">
                        <div className="subtitle">Référence fournisseur :</div>
                        {this.state.editing
                            ? <input ref={this.supplierReferenceInput} defaultValue={this.state.sparePart.supplierReference} disabled={this.state.requesting}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div>{this.state.sparePart.supplierReference || <i>Non précisée</i>}</div>}
                    </div>
                </div>

                <div className="field-group">
                    <div className="field">
                        <div className="subtitle">Prix d'achat HT :</div>
                        {this.state.editing
                            ? <input ref={this.purchasePriceHtInput} type="number" defaultValue={this.state.sparePart.purchasePriceHt} disabled={this.state.requesting}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div>{new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(this.state.sparePart.purchasePriceHt)}</div>}
                    </div>
                    <div className="field">
                        <div className="subtitle">Prix de vente TTC :</div>
                        {this.state.editing
                            ? <input ref={this.sellingPriceTtcInput} type="number" defaultValue={this.state.sparePart.sellingPriceTtc} disabled={this.state.requesting}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div>{new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(this.state.sparePart.sellingPriceTtc)}</div>}
                    </div>
                </div>

                <div className="field-group">
                    <div className="field">
                        <div className="subtitle">Stock réel :</div>
                        {this.state.editing
                            ? <input ref={this.realStockInput} type="number" defaultValue={this.state.sparePart.realStock} disabled={this.state.requesting}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div>{this.state.sparePart.realStock}</div>}
                    </div>
                    <div className="field">
                        <div className="subtitle">Position :</div>
                        {this.state.editing
                            ? <input ref={this.locationInput} defaultValue={this.state.sparePart.location} disabled={this.state.requesting}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div>{this.state.sparePart.location}</div>}
                    </div>
                </div>

                <div className="field-group">
                    <div className="field">
                        <div className="subtitle">Commentaire :</div>
                        {this.state.editing
                            ? <input ref={this.commentsInput} defaultValue={this.state.sparePart.comments} disabled={this.state.requesting}
                                onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                            : <div>{this.state.sparePart.comments || <i>Aucun commentaire</i>}</div>}
                    </div>
                </div>

                <button disabled={this.state.requesting} onClick={toggleEdit}>{this.state.editing ? "Sauvegarder" : "Modifier"}</button>
                {this.state.editing ? <button disabled={this.state.requesting} onClick={() => this.setState({ editing: false })}>Annuler</button> : null}
                {this.state.editing ? <button disabled={this.state.requesting} onClick={processDelete}>Supprimer</button> : null}
            </div>}
        </div>;
    }
}

// eslint-disable-next-line
export default (props) => <SparePart {...props} params={useParams()} navigate={useNavigate()} />;
