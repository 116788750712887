import { Component, createRef } from "react";
import { useNavigate } from "react-router-dom";
import { newSparePart } from "./api";
import { Info, Loading } from "./other";

import "./styles/spareParts.scss";

class NewSparePart extends Component {

    constructor(props) {

        super(props);

        this.name = createRef();
        this.supplier = createRef();
        this.reference = createRef();
        this.supplierReference = createRef();
        this.maker = createRef();
        this.purchasePriceHt = createRef();
        this.sellingPriceTtc = createRef();
        this.realStock = createRef();
        this.location = createRef();
        this.comments = createRef();
        this.createButton = createRef();

        this.state = { requesting: false, info: null };
    }

    render() {

        document.title = "Nouvelle pièce détachée - Servelec SAV";

        const createSparePart = () => {

            const purchasePriceHt = parseFloat(this.purchasePriceHt.current.value);
            if (isNaN(purchasePriceHt)) {
                this.setState({ info: <Info>Le prix d'achat HT doit être un nombre valide !</Info> });
                return;
            }

            const sellingPriceTtc = parseFloat(this.sellingPriceTtc.current.value);
            if (isNaN(sellingPriceTtc)) {
                this.setState({ info: <Info>Le prix de vente TTC doit être un nombre valide !</Info> });
                return;
            }

            const realStock = parseInt(this.realStock.current.value);
            if (isNaN(realStock)) {
                this.setState({ info: <Info>Le stock réel doit être un nombre valide !</Info> });
                return;
            }

            this.setState({ requesting: true, info: null });
            newSparePart({
                name: this.name.current.value,
                supplier: this.supplier.current.value,
                reference: this.reference.current.value,
                supplierReference: this.supplierReference.current.value,
                maker: this.maker.current.value,
                purchasePriceHt: purchasePriceHt,
                sellingPriceTtc: sellingPriceTtc,
                realStock: realStock,
                location: this.location.current.value,
                comments: this.comments.current.value
            }).then((sparePartId) => {
                this.props.navigate("/spareParts/" + sparePartId);
            }).catch((message) => {
                if (message === "Invalid token") {
                    localStorage.removeItem("token");
                    window.location.reload();
                } else if (message === "Name cannot be empty")
                    this.setState({ requesting: false, info: <Info>Veuillez préciser un nom</Info> });
                else if (message === "Name too long")
                    this.setState({ requesting: false, info: <Info>Le nom ne doit pas faire plus de 50 caractères</Info> });
                else if (message === "Supplier too long")
                    this.setState({ requesting: false, info: <Info>Le fournisseur ne doit pas faire plus de 20 caractères</Info> });
                else if (message === "Reference too long")
                    this.setState({ requesting: false, info: <Info>La référence ne doit pas faire plus de 20 caractères</Info> });
                else if (message === "Supplier reference too long")
                    this.setState({ requesting: false, info: <Info>La référence fournisseur ne doit pas faire plus de 20 caractères</Info> });
                else if (message === "Maker too long")
                    this.setState({ requesting: false, info: <Info>Le fabricant ne doit pas faire plus de 20 caractères</Info> });
                else if (message === "Location cannot be empty")
                    this.setState({ requesting: false, info: <Info>Veuillez préciser une position</Info> });
                else if (message === "Location too long")
                    this.setState({ requesting: false, info: <Info>La position ne doit pas faire plus de 20 caractères</Info> });
                else if (message === "Comments too long")
                    this.setState({ requesting: false, info: <Info>Les commentaires ne doivent pas faire plus de 500 caractères</Info> });
                else this.setState({ requesting: false, info: <Info>Un problème est survenu</Info> });
            });
        }

        return <div className="newSparePart">

            <div className="title">{document.title}</div>

            {this.state.info}
            {this.state.requesting ? <Loading /> : null}

            <div className="form">
                <div className="field">
                    <span>Nom : </span>
                    <input ref={this.name} disabled={this.state.requesting} autoFocus
                        onKeyDown={(event) => event.key === "Enter" ? this.supplier.current.focus() : null} />
                </div>
                <div className="field">
                    <span>Fournisseur : </span>
                    <input ref={this.supplier} disabled={this.state.requesting}
                        onKeyDown={(event) => event.key === "Enter" ? this.reference.current.focus() : null} />
                </div>
                <div className="field">
                    <span>Référence : </span>
                    <input ref={this.reference} disabled={this.state.requesting}
                        onKeyDown={(event) => event.key === "Enter" ? this.supplierReference.current.focus() : null} />
                </div>
                <div className="field">
                    <span>Référence fournisseur : </span>
                    <input ref={this.supplierReference} disabled={this.state.requesting}
                        onKeyDown={(event) => event.key === "Enter" ? this.maker.current.focus() : null} />
                </div>
                <div className="field">
                    <span>Fabriquant : </span>
                    <input ref={this.maker} disabled={this.state.requesting}
                        onKeyDown={(event) => event.key === "Enter" ? this.purchasePriceHt.current.focus() : null} />
                </div>
                <div className="field">
                    <span>Prix d'achat HT : </span>
                    <input ref={this.purchasePriceHt} type="number" disabled={this.state.requesting} defaultValue={0}
                        onKeyDown={(event) => event.key === "Enter" ? this.sellingPriceTtc.current.focus() : null} />
                </div>
                <div className="field">
                    <span>Prix de vente TTC : </span>
                    <input ref={this.sellingPriceTtc} type="number" disabled={this.state.requesting} defaultValue={0}
                        onKeyDown={(event) => event.key === "Enter" ? this.realStock.current.focus() : null} />
                </div>
                <div className="field">
                    <span>Stock réel : </span>
                    <input ref={this.realStock} type="number" disabled={this.state.requesting} defaultValue={1}
                        onKeyDown={(event) => event.key === "Enter" ? this.location.current.focus() : null} />
                </div>
                <div className="field">
                    <span>Position : </span>
                    <input ref={this.location} disabled={this.state.requesting}
                        onKeyDown={(event) => event.key === "Enter" ? this.comments.current.focus() : null} />
                </div>
                <div className="field large">
                    <span>Commentaire : </span>
                    <input ref={this.comments} disabled={this.state.requesting}
                        onKeyDown={(event) => event.key === "Enter" ? this.createButton.current.click() : null} />
                </div>
                <div className="field">
                    <button ref={this.createButton} disabled={this.state.requesting} onClick={createSparePart}>Créer la pièce détachée</button>
                </div>
            </div>
        </div>
    }
}

// eslint-disable-next-line
export default (props) => <NewSparePart {...props} navigate={useNavigate()} />;
