export const genRandomString = (size) => {
    const chars = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    for (let i = 0; i < size; i++) result += chars.charAt(Math.floor(Math.random() * chars.length));
    return result;
}

export const formatPhone = (phone) => {
    const containsPlus = phone.value.startsWith("+");
    phone.value = phone.value.replace(/\D/g, "");
    phone.value = (phone.value.match(/.{1,2}/g) || []).join(" ");
    phone.value = containsPlus ? ("+" + phone.value.substring(0, 17)) : phone.value.substring(0, 14);
}

export const getWeekNumber = (date) => {
    const tdt = new Date(date.valueOf());
    const dayn = (date.getDay() + 6) % 7;
    tdt.setDate(tdt.getDate() - dayn + 3);
    const firstThursday = tdt.valueOf();
    tdt.setMonth(0, 1);
    if (tdt.getDay() !== 4) tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
    return 1 + Math.ceil((firstThursday - tdt) / 604800000);
}

export const getTranslatedMonth = (date) => {
    const months = ["Janvier", "Février", "Mars", "Avril", "Mai", "Juin", "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre"];
    return months[date.getMonth()];
}
