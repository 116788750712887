import { Component, createRef } from "react";
import { useNavigate } from "react-router-dom";
import { getEquipmentsBrands, getEquipmentsTypes, newLentEquipment } from "./api";
import { Info, Loading, SearchBar } from "./other";

import "./styles/lentEquipments.scss";

class NewLentEquipment extends Component {

    constructor(props) {

        super(props);

        this.type = createRef();
        this.brand = createRef();
        this.model = createRef();
        this.pnc = createRef();
        this.serialNumber = createRef();
        this.createButtonRef = createRef();

        this.state = { requesting: false, info: null };
    }

    render() {

        document.title = "Nouvel appareil de prêt - Servelec SAV";

        const createLentEquipment = () => {

            this.setState({ requesting: true, info: null });
            newLentEquipment({
                type: this.type.current.value,
                brand: this.brand.current.value,
                model: this.model.current.value,
                serialNumber: this.serialNumber.current.value,
                pnc: this.pnc.current.value
            }).then((lentEquipmentId) => {
                this.props.navigate("/lentEquipments/" + lentEquipmentId);
            }).catch((message) => {
                if (message === "Invalid token") {
                    localStorage.removeItem("token");
                    window.location.reload();
                } else this.setState({ requesting: false, info: <Info>Un problème est survenu</Info> });
            });
        }

        return <div className="newLentEquipment">

            <div className="title">{document.title}</div>

            {this.state.info}
            {this.state.requesting ? <Loading /> : null}

            <div className="form">
                <div className="field">
                    <span>Type : </span>
                    <SearchBar ref={this.type} disabled={this.state.requesting}
                        onEnter={() => this.brand.current.focus()}
                        getOptions={async (value) => (await getEquipmentsTypes(value)).map((type) => ({ name: type.name, value: type.id }))} />
                </div>
                <div className="field">
                    <span>Marque : </span>
                    <SearchBar ref={this.brand} disabled={this.state.requesting}
                        onEnter={() => this.model.current.focus()}
                        getOptions={async (value) => (await getEquipmentsBrands(value)).map((brand) => ({ name: brand.name, value: brand.id }))} />
                </div>
                <div className="field">
                    <span>Modèle : </span>
                    <input ref={this.model} disabled={this.state.requesting}
                        onKeyDown={(event) => event.key === "Enter" ? this.serialNumber.current.focus() : null} />
                </div>
                <div className="field">
                    <span>Numéro de série : </span>
                    <input ref={this.serialNumber} disabled={this.state.requesting}
                        onKeyDown={(event) => event.key === "Enter" ? this.pnc.current.focus() : null} />
                </div>
                <div className="field">
                    <span>Numéro PNC : </span>
                    <input ref={this.pnc} disabled={this.state.requesting}
                        onKeyDown={(event) => event.key === "Enter" ? this.createButtonRef.current.click() : null} />
                </div>
                <div className="field">
                    <button ref={this.createButtonRef} disabled={this.state.requesting} onClick={createLentEquipment}>Créer l'appareil de prêt</button>
                </div>
            </div>
        </div>
    }
}

// eslint-disable-next-line
export default (props) => <NewLentEquipment {...props} navigate={useNavigate()} />;
