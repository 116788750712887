import { Component } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { File, Info, Loading } from "./other";
import { getFiles, getHomeFilesGroups } from "./api";

import "./styles/home.scss";

class Home extends Component {

    constructor(props) {

        super(props);

        this.state = { requesting: false, info: null, filesGroups: [], files: null };
    }

    componentDidUpdate(oldProps) {
        if (oldProps.params !== this.props.params) this.processQuery();
    }

    processQuery() {

        let group = this.state.filesGroups[2];

        if (typeof this.props.params.groupIndex !== "undefined") {

            const index = parseInt(this.props.params.groupIndex);
            if (isNaN(index)) {
                this.setState({ info: <Info>Ce numéro de groupe n'est pas valide !</Info> });
                return;
            }

            group = this.state.filesGroups[index];

            if (!group) {
                this.setState({ info: <Info>Ce numéro de groupe n'existe pas !</Info> });
                return;
            }
        }

        const oldSelected = this.state.filesGroups.find((group) => group.selected);
        if (oldSelected) oldSelected.selected = false;
        group.selected = true;

        this.setState({ requesting: true, info: null, filesGroups: this.state.filesGroups, files: null });

        getFiles(group.options).then((files) => {
            if (typeof group.count !== "undefined") group.count = files.length;
            this.setState({ requesting: false, filesGroups: this.state.filesGroups, files });
        }).catch((message) => {
            if (message === "Invalid token") {
                localStorage.removeItem("token");
                window.location.reload();
            } else this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
        });
    }

    componentDidMount() {

        this.setState({ requesting: true });

        getHomeFilesGroups().then((groups) => {
            this.setState({ requesting: false, filesGroups: groups }, () => this.processQuery());
        }).catch((message) => {
            if (message === "Invalid token") {
                localStorage.removeItem("token");
                window.location.reload();
            } else this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
        });
    }

    render() {

        document.title = "Accueil - Servelec SAV";

        return <div className="home">

            <div className="title">{document.title}</div>

            <div className="box">
                <div className="title">Listes des fiches</div>

                <div className="lists">{this.state.filesGroups.map((group, index) =>
                    <button disabled={this.state.requesting} key={index} className={group.selected ? "selected" : ""} onClick={() => this.props.navigate("/home/" + index)}>{group.name}{typeof group.count !== "undefined" ? " (" + group.count + ")" : ""}</button>
                )}</div>

                {this.state.info}
                {this.state.requesting ? <Loading /> : null}

                {!this.state.files ? null : <div className="files">{this.state.files.length === 0
                    ? "Aucune fiche"
                    : <table>
                        <thead><tr>
                            <th>Date</th>
                            <th>Client</th>
                            <th>Téléphone fixe</th>
                            <th>Téléphone portable</th>
                            <th>Matériel</th>
                            <th>Statut</th>
                        </tr></thead>
                        <tbody>{this.state.files.sort((a, b) => {
                            const selectedGroup = this.state.filesGroups.find((group) => group.selected);
                            if (typeof selectedGroup.count !== "undefined" && a.priority && !b.priority) return -1;
                            if (typeof selectedGroup.count !== "undefined" && !a.priority && b.priority) return 1;
                            return a.date - b.date;
                        }).map((file) => <File key={file.id} file={file} />)}</tbody>
                    </table>
                }</div>}
            </div>
        </div>;
    }
}

// eslint-disable-next-line
export default (props) => <Home {...props} navigate={useNavigate()} params={useParams()} />;
