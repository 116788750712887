import { Component, createRef } from "react";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment";

class HeaderClass extends Component {

    constructor(props) {
        super(props);

        this.searchRef = createRef();
    }

    render() {

        const search = () => {

            const [searchParams] = this.props.searchParams;

            const params = new URLSearchParams();
            params.set("query", this.searchRef.current.value);
            params.set("queryType", "files");
            if (this.props.location.pathname.toLowerCase() === "/search" && searchParams.has("queryType"))
                params.set("queryType", searchParams.get("queryType"));
            else if (this.props.location.pathname.toLowerCase() === "/spareparts")
                params.set("queryType", "spareParts");
            else if (this.props.location.pathname.toLowerCase() === "/lentequipments")
                params.set("queryType", "lentEquipments");
            if ((this.props.location.pathname.toLowerCase() === "/lentequipments" || this.props.location.pathname.toLowerCase() === "/search") && searchParams.has("lending"))
                params.set("lending", searchParams.get("lending"));

            this.props.navigate("/search?" + params.toString());
            this.searchRef.current.value = "";
            this.searchRef.current.blur();
        }

        return <div className="header">

            <div className="top">
                <div className="left">
                    <div className="logo">
                        <img src="/imgs/logo.png" alt="Logo" />
                        <span>SAV Servelec</span>
                    </div>

                    <div className="links">
                        <Link to="/home" className="link">Accueil</Link>
                        <Link to="/files/new" className="link">Nouvelle fiche</Link>
                        <Link to="/spareParts" className="link">Pièces détachées</Link>
                        <Link to="/lentEquipments" className="link">Appareils de prêt</Link>
                        <Link to="/statistics" className="link">Statistiques</Link>
                    </div>
                </div>

                <div className="search">
                    <i className="fas fa-search" onClick={() => search()} />
                    <input ref={this.searchRef} placeholder="Rechercher" onKeyDown={(event) => { if (event.key === "Enter") { search(); } }} />
                </div>
            </div>

            <div className="line" />
        </div>;
    }
}

// eslint-disable-next-line
export const Header = (props) => <HeaderClass {...props} location={useLocation()} navigate={useNavigate()} searchParams={useSearchParams()} />;

export class NotFound extends Component {
    render() {
        document.title = "Page introuvable - Servelec SAV";
        return <div>Cette page n'existe pas (Ou pas encore) ! <Link to="/home">Retour à l'accueil</Link></div>;
    }
}

export class Info extends Component {
    render() {
        return <div className="info" style={{ color: this.props.color || "red" }}>{this.props.children}</div>
    }
}

class BarCodeScannerClass extends Component {
    componentDidMount() {

        const prefix = "SAVF";
        let typing = "";

        document.addEventListener("keypress", (event) => {

            const inputting = ["input", "textarea"].includes(document.activeElement.tagName.toLowerCase());

            if (event.key === "Enter") {

                if (typing.includes(prefix)) {

                    const code = parseInt(typing.slice(typing.indexOf(prefix) + prefix.length));
                    if (isNaN(code)) {
                        typing = "";
                        return;
                    }

                    event.stopPropagation();
                    if (inputting)
                        document.activeElement.value = document.activeElement.value.slice(0, 0 - prefix.length - code.toString().length);

                    this.props.navigate("/files/" + code);
                }

                typing = "";

            } else typing += event.key;

        }, true);
    }

    render() {
        return null;
    }
}

export const BarCodeScanner = (props) => <BarCodeScannerClass {...props} navigate={useNavigate()} />;

export class Loading extends Component {
    render() {
        return <div className="loading"><i className="fas fa-spinner" /></div>
    }
}

export class SearchBar extends Component {

    constructor(props) {

        super(props);

        this.selfRef = createRef();
        this.inputRef = createRef();
        this.optionsRef = createRef();
        this.hoveredRef = createRef();

        this.value = "";
        this.disableNextSearch = false;
        this.state = { options: [], hovered: 0, displayed: false, optionsWidth: 0 };
    }

    componentDidMount() {
        this.onWindowResize();
        window.addEventListener("resize", this.onWindowResize);
        document.addEventListener("mousedown", this.onDocumentClick);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.onWindowResize);
        document.removeEventListener("mousedown", this.onDocumentClick);
    }

    onWindowResize = () => {
        this.setState({ optionsWidth: this.inputRef.current.offsetWidth });
    }

    onDocumentClick = (event) => {
        if (!this.selfRef.current.contains(event.target))
            this.setState({ displayed: false });
    }

    focus() {
        this.inputRef.current.focus();
    }

    clear() {
        this.inputRef.current.value = "";
        this.value = "";
        this.setState({ options: [], hovered: -1, displayed: false });
    }

    async setValue(value) {
        if (typeof value === "number") {
            this.value = value;
            this.inputRef.current.value = (await this.props.getOptions()).find((option) => option.value === value).name;
        } else {
            this.value = value;
            this.inputRef.current.value = value;
        }
        this.setState({ displayed: false });
    }

    render() {

        const search = async () => {
            if (this.disableNextSearch) {
                this.disableNextSearch = false;
                return;
            }
            this.value = this.inputRef.current.value;
            if (this.props.onChange) this.props.onChange();
            this.setState({ options: await this.props.getOptions(this.value), hovered: -1, displayed: true });
        }

        return <div className="autocomplete" ref={this.selfRef}>
            <input ref={this.inputRef} disabled={this.props.disabled}
                className={this.state.displayed && this.state.options.length !== 0 ? "active" : ""}
                onChange={() => search()} onFocus={() => search()}
                onKeyDown={(event) => {
                    if (event.key === "Enter") {
                        if (this.state.displayed && this.state.options.length > 0 && this.state.options[this.state.hovered]) {
                            const option = this.state.options[this.state.hovered];
                            this.value = option.value;
                            this.inputRef.current.value = option.name;
                            if (this.props.onChange) this.props.onChange();
                        } else if (this.props.onEnter) this.props.onEnter();
                        this.setState({ displayed: false });
                    } else if (event.key === "Escape" || event.key === "Tab")
                        this.setState({ displayed: false });
                    else if (event.key === "ArrowUp" && this.state.hovered >= 0)
                        this.setState({ hovered: this.state.hovered - 1 }, () => {
                            if (this.hoveredRef.current && this.hoveredRef.current.offsetTop < this.optionsRef.current.scrollTop)
                                this.optionsRef.current.scrollTop = this.hoveredRef.current.offsetTop;
                        });
                    else if (event.key === "ArrowDown" && this.state.hovered < this.state.options.length - 1)
                        this.setState({ hovered: this.state.hovered + 1 }, () => {
                            if (this.hoveredRef.current && this.hoveredRef.current.offsetTop + this.hoveredRef.current.offsetHeight > this.optionsRef.current.scrollTop + this.optionsRef.current.offsetHeight)
                                this.optionsRef.current.scrollTop = this.hoveredRef.current.offsetTop + this.hoveredRef.current.offsetHeight - this.optionsRef.current.offsetHeight;
                        });
                }} />

            {this.state.displayed && this.state.options.length !== 0
                ? <div className="options" ref={this.optionsRef} style={{ width: this.state.optionsWidth }}>
                    {this.state.options.map((option, i) => <div key={option.value} ref={this.state.hovered === i ? this.hoveredRef : null}
                        className={"option" + (this.state.hovered === i ? " hovered" : "")}
                        onMouseOver={() => this.setState({ hovered: i })}
                        onClick={() => {
                            this.value = option.value;
                            this.inputRef.current.value = option.name;
                            this.setState({ displayed: false });
                            this.disableNextSearch = true;
                            this.inputRef.current.focus();
                            if (this.props.onChange) this.props.onChange();
                        }}>
                        <i className="fas fa-search" />
                        {option.name}
                    </div>)}
                </div>
                : null}
        </div>;
    }
}

class FileClass extends Component {
    render() {
        return <tr onClick={() => this.props.navigate("/files/" + this.props.file.id)} className={this.props.file.priority ? "priority" : ""}>
            <td className="nowrap">{moment(this.props.file.date).format("DD/MM/YYYY")}</td>
            <td>{this.props.file.client.firstName + " " + this.props.file.client.lastName}</td>
            <td className="nowrap">{this.props.file.client.phone}</td>
            <td className="nowrap">{this.props.file.client.cellphone}</td>
            <td>{this.props.file.equipment.type.name + " " + this.props.file.equipment.brand.name}</td>
            <td className="nowrap" style={{ color: [1, 2, 3, 4, 5, 6, 22, 23].includes(this.props.file.state.id) ? "red" : "" }}>{this.props.file.state.name}</td>
        </tr>;
    }
}

class ClientClass extends Component {
    render() {
        return <tr onClick={() => this.props.navigate("/clients/" + this.props.client.id)}>
            <td>{this.props.client.lastName}</td>
            <td>{this.props.client.firstName}</td>
            <td>{this.props.client.city}</td>
            <td className="nowrap">{this.props.client.postalCode}</td>
            <td>{this.props.client.address}</td>
            <td className="nowrap">{this.props.client.phone}</td>
            <td className="nowrap">{this.props.client.cellphone}</td>
            <td className="nowrap">{this.props.client.email}</td>
        </tr>;
    }
}

class EquipmentClass extends Component {
    render() {
        return <tr onClick={() => this.props.navigate("/equipments/" + this.props.equipment.id)}>
            <td>{this.props.equipment.type.name}</td>
            <td>{this.props.equipment.brand.name}</td>
            <td>{this.props.equipment.model}</td>
            <td className="nowrap">{this.props.equipment.serialNumber}</td>
            <td className="nowrap">{this.props.equipment.pnc}</td>
        </tr>;
    }
}

class TypeClass extends Component {
    render() {
        return <tr onClick={() => this.props.navigate("/equipmentsTypes/" + this.props.type.id)}>
            <td>{this.props.type.name}</td>
            <td className="nowrap">{this.props.type.equipmentsCount}</td>
        </tr>;
    }
}

class BrandClass extends Component {
    render() {
        return <tr onClick={() => this.props.navigate("/equipmentsBrands/" + this.props.brand.id)}>
            <td>{this.props.brand.name}</td>
            <td className="nowrap">{this.props.brand.equipmentsCount}</td>
        </tr>;
    }
}

class SparePartClass extends Component {
    render() {
        return <tr onClick={() => this.props.navigate("/spareParts/" + this.props.sparePart.id)}>
            <td>{this.props.sparePart.name}</td>
            <td>{this.props.sparePart.reference}</td>
            <td>{this.props.sparePart.supplierReference}</td>
            <td>{this.props.sparePart.maker}</td>
            <td>{new Intl.NumberFormat("fr-FR", { style: "currency", currency: "EUR" }).format(this.props.sparePart.purchasePriceHt)}</td>
            <td>{this.props.sparePart.realStock}</td>
            <td>{this.props.sparePart.location}</td>
            <td>{this.props.sparePart.comments}</td>
        </tr>;
    }
}

class LentEquipmentClass extends Component {
    render() {
        return <tr onClick={() => this.props.navigate(this.props.url || ("/lentEquipments/" + this.props.lentEquipment.id))} className={this.props.lentEquipment.lastLoanDate && !this.props.lentEquipment.lastLoanReturnDate ? "onloan" : ""}>
            <td>{this.props.lentEquipment.type.name}</td>
            <td>{this.props.lentEquipment.brand.name}</td>
            <td>{this.props.lentEquipment.model}</td>
            <td className="nowrap">{this.props.lentEquipment.serialNumber}</td>
            <td className="nowrap">{this.props.lentEquipment.pnc}</td>
            <td>{this.props.lentEquipment.lastRevisionDate ? "Le " + moment(this.props.lentEquipment.lastRevisionDate).format("DD/MM/YYYY") : "Inconnue"}</td>
            {typeof this.props.lentEquipment.lastLoanDate !== "undefined" ? <td className="nowrap">{this.props.lentEquipment.lastLoanDate ? "Oui, le " + moment(this.props.lentEquipment.lastLoanDate).format("DD/MM/YYYY") : "Non"}</td> : null}
            {typeof this.props.lentEquipment.lastLoanReturnDate !== "undefined" ? <td className="nowrap">{this.props.lentEquipment.lastLoanReturnDate ? "Oui, le " + moment(this.props.lentEquipment.lastLoanReturnDate).format("DD/MM/YYYY") : "Non"}</td> : null}
        </tr>;
    }
}

// eslint-disable-next-line
export const File = (props) => <FileClass {...props} navigate={useNavigate()} />;

// eslint-disable-next-line
export const Client = (props) => <ClientClass {...props} navigate={useNavigate()} />;

// eslint-disable-next-line
export const Equipment = (props) => <EquipmentClass {...props} navigate={useNavigate()} />;

// eslint-disable-next-line
export const Type = (props) => <TypeClass {...props} navigate={useNavigate()} />;

// eslint-disable-next-line
export const Brand = (props) => <BrandClass {...props} navigate={useNavigate()} />;

// eslint-disable-next-line
export const SparePart = (props) => <SparePartClass {...props} navigate={useNavigate()} />;

// eslint-disable-next-line
export const LentEquipment = (props) => <LentEquipmentClass {...props} navigate={useNavigate()} />;

export class TroubleshootingPaymentInput extends Component {

    constructor(props) {

        super(props);

        this.inputRef = createRef();

        this.value = props.defaultValue || "";

        this.state = { presetValue: this.parse(this.value) || this.parse(""), customValue: this.value, custom: !this.parse(this.value) };
    }

    parse(value) {
        if (/^\d+€ (CB|Espèces|Chèque)$/.test(value))
            return { price: parseInt(value.split("€")[0]), method: value.split("€ ")[1] };
        else if (/^\d+€$/.test(value))
            return { price: parseInt(value.split("€")[0]), method: null };
        else if (/^(CB|Espèces|Chèque)$/.test(value))
            return { price: null, method: value };
        else if (/^$/.test(value))
            return { price: null, method: null };
        else
            return null;
    }

    stringify(value) {
        if (value.price && value.method)
            return value.price + "€ " + value.method;
        else if (value.price)
            return value.price + "€";
        else if (value.method)
            return value.method;
        else
            return "";
    }

    render() {

        const onChange = (event) => {
            if (!this.state.custom) {
                const value = { ...this.state.presetValue, ...event };
                this.value = this.stringify(value);
                this.setState({ customValue: this.value, presetValue: value });
            } else {
                this.value = event.target.value;
                this.setState({ customValue: this.value, presetValue: this.parse(this.value) || this.parse("") });
            }
        }

        return <div className="troubleshooting-payment-input">
            {!this.state.custom ? <>

                <span>
                    <span>
                        <input type="radio" name="payed" id="payed" checked={this.state.presetValue.price === null && this.state.presetValue.method === null} disabled={this.props.disabled}
                            onChange={() => onChange(this.parse(""))} />
                        <label htmlFor="payed">Non réglée</label>
                    </span>
                </span>

                <span>
                    <span>
                        <input type="radio" name="price" id="price-17" checked={this.state.presetValue.price === 17} disabled={this.props.disabled}
                            onChange={() => onChange({ price: 17 })} />
                        <label htmlFor="price-17">17€</label>
                    </span>
                    <span>
                        <input type="radio" name="price" id="price-29" checked={this.state.presetValue.price === 29} disabled={this.props.disabled}
                            onChange={() => onChange({ price: 29 })} />
                        <label htmlFor="price-29">29€</label>
                    </span>
                    {this.state.presetValue.price !== null && ![17, 29].includes(this.state.presetValue.price) ? <span>
                        <input type="radio" name="price" id="price-custom" value="custom" checked disabled={this.props.disabled}
                            onChange={() => { }} />
                        <label htmlFor="price-custom">{this.state.presetValue.price}€</label>
                    </span> : null}
                </span>

                <span>
                    <span>
                        <input type="radio" name="method" id="method-cb" checked={this.state.presetValue.method === "CB"} disabled={this.props.disabled}
                            onChange={() => onChange({ method: "CB" })} />
                        <label htmlFor="method-cb">CB</label>
                    </span>
                    <span>
                        <input type="radio" name="method" id="method-cash" checked={this.state.presetValue.method === "Espèces"} disabled={this.props.disabled}
                            onChange={() => onChange({ method: "Espèces" })} />
                        <label htmlFor="method-cash">Espèces</label>
                    </span>
                    <span>
                        <input type="radio" name="method" id="method-check" checked={this.state.presetValue.method === "Chèque"} disabled={this.props.disabled}
                            onChange={() => onChange({ method: "Chèque" })} />
                        <label htmlFor="method-check">Chèque</label>
                    </span>
                </span>

                <button disabled={this.props.disabled} onClick={() => this.setState({ custom: true }, () => this.inputRef.current.focus())}>Personnalisé</button>

            </> : <>

                <input ref={this.inputRef} type="text" value={this.state.customValue} disabled={this.props.disabled} onKeyDown={this.props.onKeyDown || (() => { })}
                    onChange={onChange} />

                <button disabled={this.props.disabled} onClick={() => this.setState({ custom: false }, () => onChange({}))}>Préréglages</button>

            </>}
        </div>;
    }
}
