import { Component, createRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Info, Loading } from "./other";
import { deleteEquipmentsBrand, editEquipmentsBrand, getEquipmentsBrand } from "./api";

import "./styles/equipmentsBrand.scss";

class EquipmentsBrand extends Component {

    constructor(props) {

        super(props);

        this.nameInput = createRef();

        this.state = { requesting: false, info: null, brand: null, editing: false };
    }

    componentDidMount() {

        const id = parseInt(this.props.params.brandId);
        if (isNaN(id)) {
            this.setState({ info: <Info>Ce numéro de marque n'est pas valide !</Info> });
            return;
        }

        this.setState({ requesting: true, info: null });
        getEquipmentsBrand(id, true).then((brand) => {
            this.setState({ requesting: false, brand });
        }).catch((error) => {
            if (error === "Invalid token") {
                localStorage.removeItem("token");
                window.location.reload();
            } else if (error === "This brand does not exist")
                this.setState({ requesting: false, info: <Info>Cette marque n'existe pas !</Info> });
            else this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
        });
    }

    render() {

        document.title = `Marque ${this.state.brand ? this.state.brand.name : ""} - Servelec SAV`;

        const toggleEdit = () => {

            if (!this.state.editing) {
                this.setState({ editing: true });
                return;
            }

            const edits = {};
            if (this.nameInput.current.value !== this.state.brand.name) edits.name = this.nameInput.current.value;

            if (Object.keys(edits).length === 0) {
                this.setState({ editing: false });
                return;
            }

            this.setState({ requesting: true, info: null });
            editEquipmentsBrand(this.state.brand.id, edits, true).then((brand) => {
                this.setState({ requesting: false, editing: false, brand });
            }).catch(() => {
                this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
            });
        }

        const processDelete = () => {

            const valid = window.confirm("Voulez vous vraiment supprimer la marque ?");
            if (!valid) return;

            this.setState({ requesting: true, info: null });
            deleteEquipmentsBrand(this.state.brand.id)
                .then(() => this.props.navigate("/home"))
                .catch((error) => {
                    if (error === "This brand is used by equipments")
                        this.setState({ requesting: false, info: <Info>Cette marque est utilisée par un ou plusieurs appareils !</Info> });
                    else this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
                });
        }

        return <div className="equipmentsBrand">

            <div className="title">Marque{this.state.brand ? ` n°${this.state.brand.id}` : ""}</div>

            {this.state.info}
            {this.state.requesting ? <Loading /> : null}

            {!this.state.brand ? null : <div>

                <div className="subtitle">Nom :</div>
                {this.state.editing
                    ? <input ref={this.nameInput} defaultValue={this.state.brand.name} disabled={this.state.requesting}
                        onKeyDown={(event) => event.key === "Enter" ? toggleEdit() : null} />
                    : <div>{this.state.brand.name}</div>}

                <div className="subtitle">Nombre d'appareils :</div>
                <div>{this.state.brand.equipmentsCount}</div>

                <button disabled={this.state.requesting} onClick={toggleEdit}>{this.state.editing ? "Sauvegarder" : "Modifier"}</button>
                {this.state.editing ? <button disabled={this.state.requesting} onClick={() => this.setState({ editing: false })}>Annuler</button> : null}
                {this.state.editing ? <button disabled={this.state.requesting} onClick={processDelete}>Supprimer</button> : null}
            </div>}
        </div>;
    }
}

// eslint-disable-next-line
export default (props) => <EquipmentsBrand {...props} params={useParams()} navigate={useNavigate()} />;
