import React, { Component } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import { BarCodeScanner, Header, NotFound } from "./other";
import Login from "./login";
import Home from "./home";
import Statistics from "./statistics";
import Search from "./search";
import NewFile from "./newFile";
import File from "./file";
import Client from "./client";
import Equipment from "./equipment";
import EquipmentsBrand from "./equipmentsBrand";
import EquipmentsType from "./equipmentsType";
import SpareParts from "./spareParts";
import NewSparePart from "./newSparePart";
import SparePart from "./sparePart";
import LentEquipments from "./lentEquipments";
import NewLentEquipment from "./newLentEquipment";
import LentEquipment from "./lentEquipment";

import "./styles/common.scss";
import "react-datepicker/dist/react-datepicker.css";

import { Chart, CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend } from "chart.js";
Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

class Website extends Component {
    render() {

        if (!localStorage.getItem("token"))
            return <Login />;

        return <BrowserRouter>
            <Header />
            <BarCodeScanner />
            <div className="content"><Routes>
                <Route path="/" element={<Home />} />
                <Route path="/home" element={<Home />} />
                <Route path="/home/:groupIndex" element={<Home />} />
                <Route path="/statistics" element={<Statistics />} />
                <Route path="/search" element={<Search />} />
                <Route path="/files/new" element={<NewFile />} />
                <Route path="/files/:id" element={<File />} />
                <Route path="/clients/:id" element={<Client />} />
                <Route path="/equipments/:id" element={<Equipment />} />
                <Route path="/equipmentsBrands/:brandId" element={<EquipmentsBrand />} />
                <Route path="/equipmentsTypes/:typeId" element={<EquipmentsType />} />
                <Route path="/spareParts" element={<SpareParts />} />
                <Route path="/spareParts/new" element={<NewSparePart />} />
                <Route path="/spareParts/:id" element={<SparePart />} />
                <Route path="/lentEquipments" element={<LentEquipments />} />
                <Route path="/lentEquipments/new" element={<NewLentEquipment />} />
                <Route path="/lentEquipments/:id" element={<LentEquipment />} />
                <Route path="/*" element={<NotFound />} />
            </Routes></div>
        </BrowserRouter>;
    }
}

createRoot(document.getElementById("root")).render(<Website />);
