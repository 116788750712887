import { Component } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { getLentEquipments } from "./api";
import { Info, Loading, LentEquipment } from "./other";

import "./styles/lentEquipments.scss";

class LentEquipments extends Component {

    constructor(props) {

        super(props);

        this.state = { requesting: false, info: null, lentEquipments: null, lending: null };
    }

    componentDidMount() {

        const [searchParams] = this.props.searchParams;
        if (searchParams.has("lending") && /^[FCfc]\d+$/.test(searchParams.get("lending")))
            this.setState({ lending: searchParams.get("lending") });

        this.setState({ requesting: true });
        getLentEquipments({ withLastLoanDates: true }).then((lentEquipments) => {
            this.setState({ requesting: false, lentEquipments });
        }).catch((message) => {
            if (message === "Invalid token") {
                localStorage.removeItem("token");
                window.location.reload();
            } else this.setState({ requesting: false, info: <Info>Un problème est survenu !</Info> });
        });
    }

    render() {

        document.title = (!this.state.lending ? "Appareils de prêt" : "Prêt") + " - Servelec SAV";

        const lendingUrl = this.state.lending ? ("/" + (this.state.lending.slice(0, 1).toUpperCase() === "C" ? "clients" : "files") + "/" + this.state.lending.slice(1)) : null;

        return <div className="lentEquipments">

            <div className="title">{document.title}</div>

            {this.state.info}
            {this.state.requesting ? <Loading /> : null}

            <div className="box">
                {!this.state.lending
                    ? <Link to={!this.state.lending ? "/lentEquipments/new" : "/lentEquipments/new?lending=" + this.state.lending} className="link">Nouvel appareil de prêt</Link>
                    : <div>Veuillez choisir l'appareil à prêter, vous pouvez utiliser la recherche</div>}
            </div>

            {this.state.lentEquipments
                ? (this.state.lentEquipments.length > 0
                    ? <div className="files"><table>
                        <thead><tr>
                            <th>Type</th>
                            <th>Marque</th>
                            <th>Modèle</th>
                            <th>Numéro de série</th>
                            <th>Numéro PNC</th>
                            <th>Dernière révision</th>
                            <th>Prêté</th>
                            <th>Rendu</th>
                        </tr></thead>
                        <tbody>{this.state.lentEquipments.sort((a, b) => a.type.id - b.type.id).map((lentEquipment) => <LentEquipment key={lentEquipment.id} lentEquipment={lentEquipment} url={lendingUrl ? (lendingUrl + "?lending=" + lentEquipment.id) : null} />)}</tbody>
                    </table></div>
                    : <div className="noresults">Aucun résultat</div>)
                : null}
        </div>
    }
}

// eslint-disable-next-line
export default (props) => <LentEquipments {...props} searchParams={useSearchParams()} />;
