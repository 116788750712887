export const API_HOST = process.env.NODE_ENV === "production" ? "https://api.savsav.servelec-apt.fr" : "http://" + window.location.hostname + ":8080";

export const login = async (password) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/login", {
        method: "POST",
        body: JSON.stringify({ password })
    }).then((res) => {
        if (res.ok) res.json().then((res) => resolve(res.token)).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const getHomeFilesGroups = async () => new Promise((resolve, reject) => {

    fetch(API_HOST + "/home/filesgroups", {
        method: "GET",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => resolve(res.groups)).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const getEquipments = async (options) => new Promise((resolve, reject) => {

    const params = new URLSearchParams();
    if (typeof options.type !== "undefined") params.set("type", options.type);
    if (typeof options.typeId !== "undefined") params.set("typeId", options.typeId);
    if (typeof options.brand !== "undefined") params.set("brand", options.brand);
    if (typeof options.brandId !== "undefined") params.set("brandId", options.brandId);
    if (typeof options.model !== "undefined") params.set("model", options.model);
    if (typeof options.serialNumber !== "undefined") params.set("serialNumber", options.serialNumber);
    if (typeof options.pnc !== "undefined") params.set("pnc", options.pnc);
    if (typeof options.query !== "undefined") params.set("query", options.query);

    fetch(API_HOST + "/equipments?" + params.toString(), {
        method: "GET",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => resolve(res.equipments)).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const getEquipment = async (equipmentId) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/equipments/" + equipmentId, {
        method: "GET",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => { delete res.code; resolve(res); }).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const editEquipment = async (equipmentId, edits) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/equipments/" + equipmentId, {
        method: "PATCH",
        body: JSON.stringify(edits),
        headers: { authorization: localStorage.getItem("token") }
    }).then(async (res) => {
        if (res.ok) resolve(await getEquipment(equipmentId));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const deleteEquipment = async (equipmentId) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/equipments/" + equipmentId, {
        method: "DELETE",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) resolve();
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const getEquipmentsTypes = async (name, withEquipmentsCount = false) => new Promise((resolve, reject) => {

    const params = new URLSearchParams();
    if (typeof name !== "undefined") params.set("name", name);
    if (withEquipmentsCount) params.set("withEquipmentsCount", true);

    fetch(API_HOST + "/equipmentsTypes?" + params.toString(), {
        method: "GET",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => resolve(res.types)).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const getEquipmentsType = async (typeId, withEquipmentsCount = false) => new Promise((resolve, reject) => {

    const params = new URLSearchParams();
    if (withEquipmentsCount) params.set("withEquipmentsCount", true);

    fetch(API_HOST + "/equipmentsTypes/" + typeId + "?" + params.toString(), {
        method: "GET",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => { delete res.code; resolve(res); }).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const editEquipmentsType = async (typeId, edits, withEquipmentsCount) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/equipmentsTypes/" + typeId, {
        method: "PATCH",
        body: JSON.stringify(edits),
        headers: { authorization: localStorage.getItem("token") }
    }).then(async (res) => {
        if (res.ok) resolve(await getEquipmentsType(typeId, withEquipmentsCount));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const deleteEquipmentsType = async (typeId) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/equipmentsTypes/" + typeId, {
        method: "DELETE",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) resolve();
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const getEquipmentsBrands = async (name, withEquipmentsCount = false) => new Promise((resolve, reject) => {

    const params = new URLSearchParams();
    if (typeof name !== "undefined") params.set("name", name);
    if (withEquipmentsCount) params.set("withEquipmentsCount", true);

    fetch(API_HOST + "/equipmentsBrands?" + params.toString(), {
        method: "GET",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => resolve(res.brands)).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const getEquipmentsBrand = async (brandId, withEquipmentsCount = false) => new Promise((resolve, reject) => {

    const params = new URLSearchParams();
    if (withEquipmentsCount) params.set("withEquipmentsCount", true);

    fetch(API_HOST + "/equipmentsBrands/" + brandId + "?" + params.toString(), {
        method: "GET",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => { delete res.code; resolve(res); }).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const editEquipmentsBrand = async (brandId, edits, withEquipmentsCount) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/equipmentsBrands/" + brandId, {
        method: "PATCH",
        body: JSON.stringify(edits),
        headers: { authorization: localStorage.getItem("token") }
    }).then(async (res) => {
        if (res.ok) resolve(await getEquipmentsBrand(brandId, withEquipmentsCount));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const deleteEquipmentsBrand = async (brandId) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/equipmentsBrands/" + brandId, {
        method: "DELETE",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) resolve();
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const getClients = async (options) => new Promise((resolve, reject) => {

    const params = new URLSearchParams();
    if (typeof options.lastName !== "undefined") params.set("lastName", options.lastName);
    if (typeof options.firstName !== "undefined") params.set("firstName", options.firstName);
    if (typeof options.email !== "undefined") params.set("email", options.email);
    if (typeof options.phone !== "undefined") params.set("phone", options.phone);
    if (typeof options.cellphone !== "undefined") params.set("cellphone", options.cellphone);
    if (typeof options.city !== "undefined") params.set("city", options.city);
    if (typeof options.postalCode !== "undefined") params.set("postalCode", options.postalCode);
    if (typeof options.address !== "undefined") params.set("address", options.address);
    if (typeof options.query !== "undefined") params.set("query", options.query);

    fetch(API_HOST + "/clients?" + params.toString(), {
        method: "GET",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => resolve(res.clients)).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const getClient = async (clientId) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/clients/" + clientId, {
        method: "GET",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => { delete res.code; resolve(res); }).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const editClient = async (clientId, edits) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/clients/" + clientId, {
        method: "PATCH",
        body: JSON.stringify(edits),
        headers: { authorization: localStorage.getItem("token") }
    }).then(async (res) => {
        if (res.ok) resolve(await getClient(clientId));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const deleteClient = async (clientId) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/clients/" + clientId, {
        method: "DELETE",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) resolve();
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const getFiles = async (options) => new Promise((resolve, reject) => {

    const params = new URLSearchParams();
    if (typeof options.query !== "undefined") params.set("query", options.query);
    if (typeof options.state !== "undefined") params.set("state", options.state);
    if (typeof options.states !== "undefined") params.set("states", options.states.join(","));
    if (typeof options.limit !== "undefined") params.set("limit", options.limit);
    if (typeof options.client !== "undefined") params.set("client", options.client);
    if (typeof options.equipment !== "undefined") params.set("equipment", options.equipment);
    if (options.revertStates) params.set("revertStates", "");

    fetch(API_HOST + "/files?" + params.toString(), {
        method: "GET",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => resolve(res.files)).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const getFile = async (fileId) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/files/" + fileId, {
        method: "GET",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => { delete res.code; resolve(res); }).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const newFile = async (file) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/files", {
        method: "POST",
        body: JSON.stringify(file),
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => resolve(res.id)).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const editFile = async (fileId, edits) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/files/" + fileId, {
        method: "PATCH",
        body: JSON.stringify(edits),
        headers: { authorization: localStorage.getItem("token") }
    }).then(async (res) => {
        if (res.ok) resolve(await getFile(fileId));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const deleteFile = async (fileId) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/files/" + fileId, {
        method: "DELETE",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) resolve();
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const getFilesStates = async () => new Promise((resolve, reject) => {

    fetch(API_HOST + "/filesStates", {
        method: "GET",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => res.json()).then((res) => {
        if (res.code === 200) resolve(res.states);
        else reject(res.message);
    }).catch((error) => reject(error));
});

export const getFileLoans = async (fileId) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/files/" + fileId + "/loans", {
        method: "GET",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => resolve(res.loans)).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const newFileLoan = async (fileId, lentEquipmentId) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/files/" + fileId + "/loans", {
        method: "POST",
        headers: { authorization: localStorage.getItem("token") },
        body: JSON.stringify({ lentEquipmentId })
    }).then((res) => {
        if (res.ok) res.json().then((res) => { delete res.code; resolve(res); }).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const getComments = async (fileId) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/files/" + fileId + "/comments", {
        method: "GET",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => resolve(res.comments)).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const newComment = async (fileId, comment) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/files/" + fileId + "/comments", {
        method: "POST",
        body: JSON.stringify({ comment: comment }),
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => resolve({ id: res.id, comment, date: Date.now() })).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const editComment = async (fileId, commentId, comment) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/files/" + fileId + "/comments/" + commentId, {
        method: "PATCH",
        body: JSON.stringify({ comment: comment }),
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) resolve();
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const deleteComment = async (fileId, commentId) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/files/" + fileId + "/comments/" + commentId, {
        method: "DELETE",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) resolve();
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const getFileImages = async (fileId) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/files/" + fileId + "/images", {
        method: "GET",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => resolve(res.images)).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const newFileImage = async (fileId, buffer) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/files/" + fileId + "/images", {
        method: "POST",
        headers: { authorization: localStorage.getItem("token") },
        body: buffer
    }).then((res) => {
        if (res.ok) res.json().then((res) => resolve({ ...res, code: undefined })).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const getFileImageUrl = async (fileId, imageId, download = false) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/files/" + fileId + "/images/" + imageId + "/token", {
        method: "POST",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => resolve(API_HOST + "/files/" + fileId + "/images/" + imageId + "/" + res.token + (!download ? "/image.png" : "/download/image-" + imageId + ".png"))).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const deleteFileImage = async (fileId, imageId) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/files/" + fileId + "/images/" + imageId, {
        method: "DELETE",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) resolve();
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const getStatistics = async (since, unit) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/statistics/" + since + "/" + unit, {
        method: "GET",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => resolve(res.statistics)).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const getSpareParts = async (options = {}) => new Promise((resolve, reject) => {

    const params = new URLSearchParams();
    if (typeof options.query !== "undefined") params.set("query", options.query);
    if (typeof options.limit !== "undefined") params.set("limit", options.limit);

    fetch(API_HOST + "/spareParts?" + params.toString(), {
        method: "GET",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => resolve(res.spareParts)).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const newSparePart = async (sparePart) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/spareParts", {
        method: "POST",
        body: JSON.stringify(sparePart),
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => resolve(res.id)).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const getSparePart = async (sparePartId) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/spareParts/" + sparePartId, {
        method: "GET",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => { delete res.code; resolve(res); }).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const editSparePart = async (sparePartId, edits) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/spareParts/" + sparePartId, {
        method: "PATCH",
        body: JSON.stringify(edits),
        headers: { authorization: localStorage.getItem("token") }
    }).then(async (res) => {
        if (res.ok) resolve(await getSparePart(sparePartId));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const deleteSparePart = async (sparePartId) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/spareParts/" + sparePartId, {
        method: "DELETE",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) resolve();
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const getLentEquipments = async (options = {}) => new Promise((resolve, reject) => {

    const params = new URLSearchParams();
    if (typeof options.query !== "undefined") params.set("query", options.query);
    if (typeof options.limit !== "undefined") params.set("limit", options.limit);
    if (typeof options.withLastLoanDates !== "undefined") params.set("withLastLoanDates", "");

    fetch(API_HOST + "/lentEquipments?" + params.toString(), {
        method: "GET",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => resolve(res.lentEquipments)).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const getLentEquipment = async (lentEquipmentId) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/lentEquipments/" + lentEquipmentId, {
        method: "GET",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => { delete res.code; resolve(res); }).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const newLentEquipment = async (lentEquipment) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/lentEquipments", {
        method: "POST",
        body: JSON.stringify(lentEquipment),
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => resolve(res.id)).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const editLentEquipment = async (lentEquipmentId, edits) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/lentEquipments/" + lentEquipmentId, {
        method: "PATCH",
        body: JSON.stringify(edits),
        headers: { authorization: localStorage.getItem("token") }
    }).then(async (res) => {
        if (res.ok) resolve(await getLentEquipment(lentEquipmentId));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const deleteLentEquipment = async (lentEquipmentId) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/lentEquipments/" + lentEquipmentId, {
        method: "DELETE",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) resolve();
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const getClientLoans = async (clientId) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/clients/" + clientId + "/loans", {
        method: "GET",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => resolve(res.loans)).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const newClientLoan = async (clientId, lentEquipmentId) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/clients/" + clientId + "/loans", {
        method: "POST",
        headers: { authorization: localStorage.getItem("token") },
        body: JSON.stringify({ lentEquipmentId })
    }).then((res) => {
        if (res.ok) res.json().then((res) => { delete res.code; resolve(res); }).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const getLentEquipmentLoans = async (lentEquipmentId) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/lentEquipments/" + lentEquipmentId + "/loans", {
        method: "GET",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) res.json().then((res) => resolve(res.loans)).catch((error) => reject(error));
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const editLentEquipmentLoan = async (lentEquipmentId, loanId, edits) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/lentEquipments/" + lentEquipmentId + "/loans/" + loanId, {
        method: "PATCH",
        body: JSON.stringify(edits),
        headers: { authorization: localStorage.getItem("token") }
    }).then(async (res) => {
        if (res.ok) resolve();
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});

export const deleteLentEquipmentLoan = async (lentEquipmentId, loanId) => new Promise((resolve, reject) => {

    fetch(API_HOST + "/lentEquipments/" + lentEquipmentId + "/loans/" + loanId, {
        method: "DELETE",
        headers: { authorization: localStorage.getItem("token") }
    }).then((res) => {
        if (res.ok) resolve();
        else res.json().then((res) => reject(res.message)).catch((error) => reject(error));
    }).catch((error) => reject(error));
});
